import axios from 'axios'
// import { Http, HttpResponse } from '@capacitor-community/http';
import config from '../config.js'
// import { Preferences } from '@capacitor/preferences';

export class ApiRequestService {
// const setToken = (token) => {
//     alert(token)
//     window.localStorage.setItem('token', token)
//   }
  
//   const setTokenToHeader = () => {
//     const token = window.localStorage.getItem('token')
//     // alert("ADASDASD"+token)
//     if (!token) return
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
//   }
  
//   const getToken = () => {
//     const token = window.localStorage.getItem('token')
//     if (!token) return null
//     return token
//   }
  
//   const removeToken = () => {
//     window.localStorage.removeItem('token')
//   }
  
//   const getProfile = async (socketId) => {
//     const token = window.localStorage.getItem('token')
//     if (!token) return
//     const res = await axios.get(`${config.api.profile}?socketId=${socketId}`)
//     return res.data
//   }

constructor() {
    // Preferences.get({ key: 'token'}).then((data:any) => {
    //     this.headers.token = data.value;
    //     console.log("!!!!", data)
    // })
}
public headers: any = {};
public token: any
public setToken(token:any) {

  window.localStorage.setItem('token', token)
  this.token = token
    // this.headers.token = token;
    // Preferences.set({ key: 'token', value: token })
}
public setTokenToHeader() {
    
  const token = window.localStorage.getItem('token')
  this.token = token
  // alert("ADASDASD"+token)
  if (!token) return
  
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
public getToken() {
    const token = window.localStorage.getItem('token')
    if (!token) return null
    return token
}
public removeToken() {
    window.localStorage.removeItem('token')
    // alert("TOKEN")
    this.token = ''

}
public async getProfile(socketId:any) {
    const token = window.localStorage.getItem('token')
    if (!token) return
    const res = await axios.get(`${config.api.profile}?socketId=${socketId}`)
    return res.data
}
async get(link:any, data:any = null) {    
}
public async post(link:any, data:any = null) {
}

public async put(link:any, data:any = null) {
}

public async delete(link:any, data:any = null) {
}

}