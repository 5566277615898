
import { ProfilePage } from '../../../modals/profile/profile.page';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NgbOffcanvas, NgbNav, NgbNavItem, NgbNavLink, NgbNavContent } from '@ng-bootstrap/ng-bootstrap';
import { contacts } from './data';
import { Contacts } from './contacts.model';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from "../../../app.service";
import config from '../../../../config.js'
import axios from 'axios'
import {ApiRequestService} from '../../../../service/requests.service'

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
/**
 * Tab-contacts component
 */
export class ContactsComponent implements OnInit {
contacts
groupType = 1
gender: any
groupInited = false;
groupName = ""
userFilter = "all"
textSearch = ""
contactsList: any;
selectedUsers:any = []

  constructor(public dataService: DataService, private offcanvasService: NgbOffcanvas, private modalService: NgbModal, public translate: TranslateService) { }

  async ngOnInit() {
    const res = await axios.get(config.api.contact)
    let contacts = []
    res.data.sort((a, b) => a.firstname > b.firstname ? 1 : -1);
    res.data.forEach(function(item, i) {
      item.total_index = i;
      contacts.push(item)
    })
    this.contacts = contacts
  }


  /**
   * Contacts modal open
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openContactsModal(content) {
    this.modalService.open(content, { centered: true });
  }
  search() {
    
  }

  getContactsList() {

// return []
    const textSearch = this.textSearch.toLowerCase()
    if (!this.contacts) return;
    const sorted = this.contacts;

    this.gender = ["", "Mr. ", "Ms. ",  "Mrs. ", "Dr. ", "Dra. "]

    const grouped = sorted.reduce((groups, contact) => {
      let text = ''
      if (!contact.selected) {
        contact.selected = false
      }
      if (textSearch) {        
        const letter = this.translate.instant(contact.firstname).charAt(0);
        groups[letter] = groups[letter] || [];
        text = (contact.fullName+" "+contact.firstname+" "+contact.lastname).toLowerCase()
        if (text.indexOf(textSearch) !== -1 &&  (this.userFilter == "all" || (this.userFilter == "workspace" && (contact.sex == 0 || contact.sex == 4 || contact.sex == 5)) || (this.userFilter == "patients" && (contact.sex == 1 || contact.sex == 2 || contact.sex == 3)))) {
          groups[letter].push(contact);
        }

        return groups;
      } else {   
        const letter = this.translate.instant(contact.firstname).charAt(0);
        groups[letter] = groups[letter] || [];
        if (this.userFilter == "all" || (this.userFilter == "workspace" && (contact.sex == 0 || contact.sex == 4 || contact.sex == 5)) || (this.userFilter == "patients" && (contact.sex == 1 || contact.sex == 2 || contact.sex == 3))) {
          groups[letter].push(contact);
        }
        return groups;
        
      }
      

    }, {});

    // // contacts list
    this.contactsList = Object.keys(grouped).map(key => ({ key, contacts: grouped[key] }));
    return this.contactsList

    // const textSearch = this.textSearch.toLowerCase()
    // console.log(this.contactsList)
    // return this.contactsList.filter((item:any) => {
    //   let text = ''

    //   if (item.type == 1 && item.member && item.member.fullName) 
    //   {
    //     text = item.member.fullName.toLowerCase()
    //     return text.indexOf(textSearch) !== -1
    //   }
    // })
  }

  async createGroup() {
    const ids = this.contacts.filter((item:any) => {
      return item.selected
    }).map((item:any) => {
      return item.id
    })
    const payload: any = {
      members: ids,
      type: this.groupType,
      lastMessage: `${this.gender[this.dataService.currentUser.sex]} ${this.dataService.currentUser.firstname} ${this.dataService.currentUser.lastname} created chat`
    }
    if (ids.length > 0) {
      payload.name = this.groupName || 'Group'
    }

    console.log(config.api.createGroup, payload)

    const group = await axios.post(config.api.createGroup, payload)

    const res = await axios.get(config.api.contact)
    let contacts = []
    res.data.sort((a, b) => a.firstname > b.firstname ? 1 : -1);
    res.data.forEach(function(item, i) {
      item.total_index = i;
      contacts.push(item)
    })
    this.contacts = contacts
    this.groupInited = false;

  }
  setUserFilter(filter) {
    this.userFilter = filter
  }

  showUserInfo(user, i) {
    if (this.groupInited) {
      if (this.contacts[user.total_index].selected) {
        this.contacts[user.total_index].selected = false;
      } else {
        this.contacts[user.total_index].selected = true;
      }

      return
    }
    // this.offcanvasService.dismiss()
    const groupCanvas = this.offcanvasService.open(ProfilePage, { panelClass: 'details-panel', position: 'end' });
    groupCanvas.componentInstance.profile = user;
    groupCanvas.componentInstance.currentUser = this.dataService.currentUser;
  }
  initGroup() {
    this.groupInited = !this.groupInited
  }


  contactSelected() {
    return this.contacts.filter((item:any) => {
      return item.selected
    })
  }
  setGroupType(type) {
    this.groupType = type
  }
}
