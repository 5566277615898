import axios from 'axios'
import config from '../config.js'

const setToken = (token) => {
  // alert(token)
  window.localStorage.setItem('token', token)
}

const setTokenToHeader = () => {
  const token = window.localStorage.getItem('token')
  // alert("ADASDASD"+token)
  if (!token) return
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const getToken = () => {
  const token = window.localStorage.getItem('token')
  if (!token) return null
  return token
}

const removeToken = () => {
  window.localStorage.removeItem('token')
}

const getProfile = async (socketId) => {
  const token = window.localStorage.getItem('token')
  if (!token) return
  const res = await axios.get(`${config.api.profile}?socketId=${socketId}`)
  return res.data
}

export default {
  setTokenToHeader,
  getProfile,
  removeToken,
  getToken,
  setToken,
}