<content class="padding">
  <header>
    <div class="group-name" *ngIf="contactSelect().length > 1">
    </div>
    <div class="user-selected">
      <div class="item-user" *ngFor="let item of contactSelect()">
        <app-avatar [data]="item"></app-avatar>
        <div class="name">{{item.fullName}}</div>
      </div>
    </div>
    <div>
      <div class="search-box chat-search-box">
        <div class="input-group mb-3 rounded-3">
          <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
            <i class="ri-search-line search-icon font-size-18"></i>
          </span>
          <input type="text" class="form-control bg-light" name="search" ngDefaultControl
            placeholder="Search user"
            aria-label="Search user" aria-describedby="basic-addon1" [(ngModel)]="textSearch" 
            autocomplete="off">
        </div>
      </div>
    </div>
  </header>
  <ul class="contact-list">
    <li  *ngFor="let item of contactList()" [ngClass]="{'hidden': (item.id == dataService.currentUser.id || item.online == false)}" >
      <div>
       <div class="chat-user-img online align-self-center me-3 ms-0">
         <img *ngIf="item.avatarFullUrl" src="{{item.avatarFullUrl}}" class="rounded-circle avatar-xs"
           alt="">
         <div class="avatar-xs" *ngIf="!item.avatarFullUrl ">
           <span class="avatar-title rounded-circle bg-soft-primary text-primary">
             {{( item.firstname ? item.firstname : "No name" ).charAt(0)}} 
           </span>
         </div>
       </div>
       <label>
         <h2>{{item.firstname}} {{item.lastname}} <span style="color:green">(online)</span> </h2>
         <h3 v-if="item.phone">{{item.telephone}}</h3> 
         <p v-if="item.address">{{item.address}}</p>
       </label>
     </div>
     <button *ngIf="!call && dataService.group.members.indexOf(item.id) === -1 && invited.indexOf(item.id) === -1" (click)="invite(item)" class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">Invite</button>
     <button *ngIf="call && dataService.group.members.indexOf(item.id) === -1 && invited.indexOf(item.id) === -1" (click)="invite(item)" class="btn btn-success font-size-16 btn-lg chat-send waves-effect waves-light">Add to call</button>
       <button *ngIf="invited.indexOf(item.id) !== -1" disabled class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light">Invited</button>
     
     </li>

     <li  *ngFor="let item of contactList()" [ngClass]="{'hidden': (item.id == dataService.currentUser.id || item.online == true)}" >
      <div>
       <div class="chat-user-img online align-self-center me-3 ms-0">
         <img *ngIf="item.avatarFullUrl" src="{{item.avatarFullUrl}}" class="rounded-circle avatar-xs"
           alt="">
         <div class="avatar-xs" *ngIf="!item.avatarFullUrl ">
           <span class="avatar-title rounded-circle bg-soft-primary text-primary">
             {{( item.firstname ? item.firstname : "No name" ).charAt(0)}}
           </span>
         </div>
       </div>
       <label>
         <h2>{{item.firstname}} {{item.lastname}}  </h2>
         <h3 v-if="item.phone">{{item.telephone}}</h3> 
         <p v-if="item.address">{{item.address}}</p>
       </label>
     </div>
     <button *ngIf="!call && dataService.group.members.indexOf(item.id) === -1 && invited.indexOf(item.id) === -1" (click)="invite(item)" class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">Invite</button>
     <button *ngIf="call && dataService.group.members.indexOf(item.id) === -1 && invited.indexOf(item.id) === -1" (click)="invite(item)" class="btn btn-success font-size-16 btn-lg chat-send waves-effect waves-light">Add to call</button>
       <button *ngIf="invited.indexOf(item.id) !== -1" disabled class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light">Invited</button>
     
     </li>
  </ul>
</content>