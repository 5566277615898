import config from './config'

let socket

export default  {
  init: (cb) => {
    socket = io(config.baseUrl, {
      path: '/api/socket.io-client',
      query: {        "user_id": window.localStorage.getItem('user_id')      }
    })
    socket.on('connect', () => {
      cb(socket.id)
    })
  },

  subscribe: (channel, callback) => {
    socket.on(channel, (doc) => {
      return callback(doc)
    })
  },

  unSubscribe: (channel) => {
    if (!socket) {
      return
    }
    socket.removeAllListeners(channel)
  },

  getSocketId: () => {
    return socket.id
  },

  close: () => {
    if (!socket) return
    socket.close()
  },

  getSocket: () => {
    return socket
  },
}
