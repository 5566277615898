import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ApiRequestService} from '../../service/requests.service'
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from "../app.service";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { LightboxModule } from 'ngx-lightbox';

import { TabsModule } from './tabs/tabs.module';

import { ChatRoutingModule } from './chat-routing.module';

import {DatePipe} from '@angular/common';

// Emoji Picker
import { PickerModule } from '@ctrl/ngx-emoji-mart';

// Simplebar
import { SimplebarAngularModule } from 'simplebar-angular';

import { IndexComponent } from './index/index.component';
import { TranslateModule } from '@ngx-translate/core';

import { ProfilePageModule } from '../modals/profile/profile.module';
// import { ContactPageModule } from '../modals/contact/contact.module';
import { InvitePageModule } from '../modals/invite/invite.module';
import { GroupPageModule } from '../modals/group/group.module';
// import { ProfilePageModule } from '../modals/group/group.module';

@NgModule({
  declarations: [IndexComponent],
  imports: [
    ProfilePageModule,
    PerfectScrollbarModule,
    LightboxModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbDropdownModule,
    InvitePageModule,
    CommonModule,
    GroupPageModule,
    FormsModule,
    ReactiveFormsModule,
    ChatRoutingModule,
    TabsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    TranslateModule,
    SimplebarAngularModule,
    PickerModule
  ],
  providers: [
    DatePipe, ApiRequestService,DataService
  ],
  exports: []
})
export class ChatModule { }
