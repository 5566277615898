
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AdduserPage } from './adduser.page';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [AdduserPage]
})
export class AdduserPageModule {}
