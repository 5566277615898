import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfilePage } from './profile.page';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    NgbAccordionModule,
    NgbModule,
    NgbModalModule,
    PerfectScrollbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbDropdownModule,
    CommonModule,
    FormsModule, 
  ],
  declarations: [ProfilePage]
})
export class ProfilePageModule {}
