<button _ngcontent-ccq-c35="" type="button" aria-label="Close" class="btn-close"></button>
<content class="padding" style="padding:30px">
  <header style="padding:0 30px">
    Upload file
  </header>
    <div style="padding:30px">
      <div class="row no-gutters">
        <div class="col-12 mb-3">
          <div>
            <label for="floatingInput">Select document type</label>
            <select name="" id=""  (change)="changeType($event)" class="form-control form-control-lg bg-light border-light">
              <option value="1">Personal Document</option>
              <option value="2">Medical Report</option>
              <option value="3">Prescriptions</option>
              <option value="4">Invoice</option>
              <option value="0">Others</option>
            </select>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div>
            <label for="floatingInput">Hide for patient</label>
            <select name="" (change)="changeHide($event)" id="" class="form-control form-control-lg bg-light border-light">
              <option value="1">Yes</option>
              <option value="0" selected="selected">No</option>
            </select>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div>
            <label for="floatingInput">Expiration date</label>
            <div class="row">
              <div class="col-6">
              <input  (change)="changeExpireYear($event)" type="text" class="form-control form-control-lg col bg-light border-light"
                placeholder="YYYY" formControlName="message" 
                ></div>
                <div class="col-3">
              <input  (change)="changeExpireMonth($event)" type="text" class="form-control form-control-lg bg-light border-light"
              placeholder="MM" formControlName="message" 
              ></div>
              <div class="col-3">
              <input  (change)="changeExpireDay($event)"  type="text" class="form-control form-control-lg bg-light border-light"
                placeholder="DD" formControlName="message" 
                ></div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div>
            <label for="floatingInput">Notes for document</label>
            <textarea  (change)="changeNote($event)" type="text" class="form-control form-control-lg bg-light border-light"
              placeholder="Enter notes" formControlName="message" 
              ></textarea>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div>
            <label for="formFile" class="form-label">Select document</label>
            <input class="form-control"  (change)="changeFile($event)" type="file" id="formFile">
          </div>
        </div>
        <span (click)="sendFile()" class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">UPLOAD</span>
      </div>
    </div>
</content>