
import util from '../../../util.js'
import { Component, Input } from '@angular/core';
import { DataService } from "../../app.service";
import config from '../../../config.js'
import axios from 'axios'
import {ApiRequestService} from '../../../service/requests.service'
import { ProfileEditPage } from '../profile-edit/profile-edit.page'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-uploaduserfile',
  templateUrl: 'uploaduserfile.page.html',
  styleUrls: ['uploaduserfile.page.scss']
})
export class UploaduserfilePage {
  @Input() public profile;
  @Input() public currentUser;
  textSearch: string = ''
  submiting: boolean = false
  groupName: string = ''
  expireYear = '';
  expireMonth = '';
  expireDay = '';
  notes = '';
  user_id = '';
  file:any;
  hide:any = "0"
  doc_group:any = "1"
  invited: any = []
  constructor(
    private modalService: NgbModal, 
    public req: ApiRequestService,
    public dataService: DataService
  ) {
  }

  async sendFile() {

    const formData = new FormData()
    console.log(this.currentUser);
    formData.append('author_id', this.currentUser.id);
    formData.append('hide', this.hide);
    formData.append('doc_group', this.doc_group);
    if (this.expireYear && this.expireDay && this.expireMonth){
      formData.append('expire_date', this.expireYear+'-'+this.expireDay+'-'+this.expireMonth);
    } else {
      formData.append('expire_date', '');
    }
    formData.append('notes', this.notes);
    formData.append('file', this.file);
    formData.append('user_id', this.profile.id);
    const headers = new Headers()
    headers.append('Content-Type', 'multipart/form-data')
    // Upload presentation logo
    const url = config.api.uploadRelatedDocument
    const res = await axios.post(url, formData)
    if (res.data.result.filename) {
      this.modalService.dismissAll();
    }
  }
  changeHide(event) {
    this.hide = event.target.value
  }
  changeType(event) {
    this.doc_group = event.target.value
  }
  changeExpireYear(event) {
    this.expireYear = event.target.value
  }
  changeExpireDay(event) {
    this.expireDay = event.target.value
  }
  changeExpireMonth(event) {
    this.expireMonth = event.target.value
  }
  changeNote(event) {
    this.notes = event.target.value
  }
  changeFile(event) {
    this.file = event.target.files[0]
  }
}
