import { Component, OnInit } from '@angular/core';
import { DataService } from "../../../app.service";
import config from '../../../../config.js'
import axios from 'axios'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AdduserPage } from '../../../modals/adduser/adduser.page'

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
/**
 * Tabs-settings component
 */
export class CompanyComponent implements OnInit {

  senderName:any;
  senderProfile:any;
  playerName:any;
  userInfo;
  text_copy = "Copy"
  contacts
  gender = ["", "Mr. ", "Ms. ",  "Mrs. ", "Dr. ", "Dra. "]
  userGroups = [
    'demo', 'Admin', 'Operator', 'Doctor'
  ]
  constructor(private modalService: NgbModal, public dataService: DataService) { }

  async ngOnInit() {
    const user = window.localStorage.getItem('currentUser');
    this.userInfo = this.dataService.currentUser;
    this.getContactsList();

  }
  async getContactsList() {
    let contacts = []
    const res = await axios.get(config.api.contact)
    res.data.sort((a, b) => a.firstname > b.firstname ? 1 : -1);
    res.data.forEach(function(item, i) {
      if (item.usergroup > 0) {
        contacts.push(item)
      }
    })
    this.contacts = contacts
  }
  // User Profile Update
  imageURL: string | undefined;
  async fileChange(event:any) {   
    
    const files = event.target.files
    if (!files || !files.length) return
    const file = files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('url', config.baseUrl)
    const headers = new Headers()
    headers.append('Content-Type', 'application/x-www-form-urlencoded')
    // Upload presentation logo
    const url = config.api.upload
    const res = await axios.post(url, formData)
      this.userInfo.avatarFullUrl = res.data.file.location
      this.userInfo.avatar = `/${res.data.file.path}`
      this.saveChanges('avatar')
  }

  // User Name Update
  edit_name(message) {    
    this.playerName = this.senderName
    document.getElementById("company_name").classList.toggle("visually-hidden");
    document.getElementById("company_name_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-company_name").classList.toggle("visually-hidden");
  }

  
  edit_website(message) {    
    this.playerName = this.senderName
    document.getElementById("company_website").classList.toggle("visually-hidden");
    document.getElementById("company_website_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-company_website").classList.toggle("visually-hidden");
  }



  
  edit_telephone(message) {    
    this.playerName = this.senderName
    document.getElementById("company_telephone").classList.toggle("visually-hidden");
    document.getElementById("company_telephone_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-company_telephone").classList.toggle("visually-hidden");
  }

  edit_address(message) {    
    this.playerName = this.senderName
    document.getElementById("company_address").classList.toggle("visually-hidden");
    document.getElementById("company_address_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-company_address").classList.toggle("visually-hidden");
  }

  // User Name Update
userNameChange() {
  this.senderName = this.playerName
    document.getElementById("user_name").classList.toggle("visually-hidden");
    document.getElementById("edit-user-name").classList.toggle("visually-hidden");
    document.getElementById("user_name_edit").classList.toggle("visually-hidden");
 
}

async saveChanges(key) {
  
  this.dataService.currentUser.company = this.userInfo.company
  

  await axios.put(config.api.updateCompany, this.dataService.currentUser)
  document.getElementById("company_"+key).classList.toggle("visually-hidden");
  document.getElementById("edit-company_"+key).classList.toggle("visually-hidden");
  document.getElementById("company_"+key+"_edit").classList.toggle("visually-hidden");
}

  copyInputMessage(inputElement){
    this.text_copy = 'Copied!'
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    setTimeout(() => {
      this.text_copy = "Copy"
    }, 1000);
  }
  alert(mess) {
    alert(mess)
  }
  async updateRole(user, value, index) {
    const payload = {user:user.id, value}
    this.contacts[index].updated = true;
    
    const group = await axios.post(config.api.updateRole, payload)
    setTimeout(() => {
      
    this.contacts[index].updated = false;
    
    }, 2000);
  }

  addUserModal() {

    const modalRef = this.modalService.open(AdduserPage, { centered: true });

    modalRef.dismissed.subscribe(data=>{this.getContactsList()})
    modalRef.closed.subscribe(data=>{this.getContactsList()})
  }
}
