import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { DataService } from "../../app.service";
import { InvitePage } from '../../modals/invite/invite.page'
import config from '../../../config.js'
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ProfilePage } from '../profile/profile.page';
import axios from 'axios'
import {ApiRequestService} from '../../../service/requests.service'
import util from '../../../util.js'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventsService } from '../../event-service';

@Component({
  selector: 'app-group',
  templateUrl: 'group.page.html',
  styleUrls: ['group.page.scss']
})

export class GroupPage {
  
  @Input() public group;
  @Input() public members;
  @Input() public currentUser;
  gender = ["", "Mr. ", "Ms. ",  "Mrs. ", "Dr. ", "Dra. "]
  constructor(private modalService: NgbModal, 
    private offcanvasService: NgbOffcanvas,
    public req: ApiRequestService,
    public dataService: DataService,
    public events: EventsService,
  ) {
  }
  
  dismiss(reason) {
    this.offcanvasService.dismiss(reason)
  }
  userInfo(user) {
    // this.offcanvasService.dismiss()
    const groupCanvas = this.offcanvasService.open(ProfilePage, { panelClass: 'details-panel',  position: 'end' });
    groupCanvas.componentInstance.profile = user;
    groupCanvas.componentInstance.currentUser = this.currentUser;
  }
  dateTime(data) {
    return util.dateTime(data)
  }

  openInvite(call = false) {
    this.dataService.group = this.group;
    const inviteModal = this.modalService.open(InvitePage, { centered: true });
    inviteModal.componentInstance.group = this.group;
    inviteModal.componentInstance.call = false;    
  }
}
