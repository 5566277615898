import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GroupPage } from './group.page';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ProfilePageModule } from '../profile/profile.module';
import { NgbTooltipModule, NgbDropdownModule, NgbAccordionModule, NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  imports: [
    NgbAccordionModule,
    ProfilePageModule,
    NgbModule,
    NgbModalModule,
    PerfectScrollbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbDropdownModule,
    CommonModule,
    FormsModule, 
  ],
  declarations: [GroupPage]
})
export class GroupPageModule {}
