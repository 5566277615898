<!-- Start Contact content -->
<div>
  <div class="p-4">
    <div class="user-chat-nav float-end">
      <div ngbTooltip="Add Contact">
        <!-- Button trigger modal -->
        
      </div>
    </div>

    <div class="user-chat-nav float-end">
      <div ngbTooltip="Create group">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-link text-decoration-none text-muted py-0"
          (click)="initGroup()">
          <span style="color:red" *ngIf="groupInited">Cancel</span>
          <span *ngIf="!groupInited">Create group</span>
        </button>
      </div>
    </div>

    <h4 class="mb-4">{{'chat.tabs.contacts.title' | translate}}</h4>

    <!-- End Add contact Modal -->

    
    <div   *ngIf="groupInited && contactSelected().length > 0"  class="btn-group mt-4">

      <button  class="btn" (click)="setGroupType(1)"
      [ngClass]=" {'btn-dark': groupType === 1,'btn-light': groupType !== 1}"
      >Workspace</button>
      <button class="btn" (click)="setGroupType(0)"
      [ngClass]="{'btn-dark': groupType === 0,'btn-light': groupType !== 0}"
      >Patient chat</button>

    </div>
    <input  *ngIf="groupInited && contactSelected().length > 0" type="text" placeholder="Enter group name" [(ngModel)]="groupName" class="form-control bg-light mt-1 ng-pristine ng-valid ng-touched" />
    
  <perfect-scrollbar *ngIf="groupInited && contactSelected().length > 0" style="height:auto !important" [config]="{useBothWheelAxes: true, suppressScrollX: false, suppressScrollY: false}" class=" user-selected chat-message-list chat-group-list" data-simplebar>
    
    <div class="selected-list"> 

    <div class="item-user" *ngFor="let item of contactSelected()">
        <div class="chat-user-img online align-self-center">
          <img *ngIf="item.avatarFullUrl" src="{{item.avatarFullUrl}}" class="rounded-circle avatar-xs"
            alt="">
          <div class="avatar-xs" *ngIf="!item.avatarFullUrl ">
            <span class="avatar-title rounded-circle"  [ngStyle]="{ 'background': item.bgColor, 'color': '#fff' }">
              {{( item.firstname ? item.firstname : "No name" ).charAt(0)}}
            </span>
          </div>
        </div>
        <div class="name">{{ gender[item.sex] }} {{item.firstname}} {{item.lastname}}</div>
      </div>
    </div> 
    </perfect-scrollbar>
    
    <button style="width:100%" *ngIf="groupInited && contactSelected().length > 0" (click)="createGroup()" class="btn btn-primary mt-1 mb-4"> Create group</button>

    <div class="search-box chat-search-box">
      <div class="input-group bg-light input-group-lg rounded-3">
        <div class="input-group-prepend">
          <button class="btn btn-link text-decoration-none text-muted pr-1" type="button">
            <i class="ri-search-line search-icon font-size-18"></i>
          </button>
        </div>
        <input type="text" class="form-control bg-light"  [(ngModel)]="textSearch"
          placeholder="{{'chat.tabs.contacts.search.placeholder' | translate}}">
      </div>
    </div>
    <div class="btn-group mt-4">

      <button class="btn" (click)="setUserFilter('all')"
      [ngClass]=" {'btn-primary': userFilter === 'all','btn-light': userFilter !== 'all'}"
      >All</button>
      <button class="btn" (click)="setUserFilter('workspace')"
      [ngClass]=" {'btn-primary': userFilter === 'workspace','btn-light': userFilter !== 'workspace'}"
      >Workspace</button>
      <button class="btn" (click)="setUserFilter('patients')"
      [ngClass]="{'btn-primary': userFilter === 'patients','btn-light': userFilter !== 'patients'}"
      >Patients</button>

    </div>
    <!-- End search-box -->
  </div>
  <!-- end p-4 -->

  <!-- Start contact lists groupInited && contactSelected().length > 0 -->
  <perfect-scrollbar [ngStyle]="{ 'height': (groupInited && contactSelected().length > 0) ? 'calc(100vh - 396px)' : '' }" class="p-4 chat-message-list chat-group-list" data-simplebar>
        
    <div *ngFor="let item of getContactsList()">
      <div *ngIf="item.contacts.length">
        <div class="p-3 font-weight-bold text-primary">
          {{item.key}}
        </div>
        <ul class="list-unstyled contact-list">
          <li *ngFor="let item of item.contacts; let i = index">
            <div (click)="showUserInfo(item, i)" class="d-flex align-items-center">
              <div class="chat-user-img online align-self-center me-3 ms-0">
                
                <img *ngIf="item.avatarFullUrl" src="{{item.avatarFullUrl}}" class="rounded-circle avatar-xs"
                  alt="">
                <div class="avatar-xs" *ngIf="!item.avatarFullUrl ">
                  <span class="avatar-title rounded-circle"  [ngStyle]="{ 'background': item.bgColor, 'color': '#fff' }">
                    {{( item.firstname ? item.firstname : "No name" ).charAt(0)}}
                  </span>
                </div>
              </div>

              <div class="flex-grow-1" >
                <h5 class="font-size-14 m-0">{{ gender[item.sex] }} {{item.firstname }} {{item.lastname }}</h5>
                <p class="text-muted text-truncate mb-1" *ngIf="item.online"><i
                  class="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>
                 
                Online</p>
            
                <p class="text-muted text-truncate mb-1" *ngIf="!item.online"><i
                  class="ri-record-circle-fill font-size-10 text-danger me-1 d-inline-block"></i>
                 
                Offline</p>
              </div>


              <input *ngIf="groupInited" [checked]="item.selected" style="width:20px; height:20px" class="checkbox"  type="checkbox">
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- end contact list A -->

  </perfect-scrollbar>
  <!-- end contact lists -->
</div>
<!-- Start Contact content -->
