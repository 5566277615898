import { Injectable } from "@angular/core";

@Injectable()
export class DataService {
  currentUser: any = {};
  groups: any = []
  group: any = {}
  posts: any = []
  pagePost: any = 1
  totalPost: any = 0
  countPostNotRead: any = 0
  liveStreamCount: any = 0
  startLiveStream: any = false

  constructor(
  ) {
  }
}
