
import util from '../../../util.js'
import { Component, Input } from '@angular/core';
import { DataService } from "../../app.service";
import config from '../../../config.js'
import axios from 'axios'
import {ApiRequestService} from '../../../service/requests.service'
import { ProfileEditPage } from '../profile-edit/profile-edit.page'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-adduser',
  templateUrl: 'adduser.page.html',
  styleUrls: ['adduser.page.scss']
})
export class AdduserPage {
  firstname = "";
  lastname = "";
  password = "";
  confirmPassword = "";
  email = "";
  telephone: string = "";
  sex = 1;
  usergroup = 1;
  error:any;
  constructor(
    private modalService: NgbModal, 
    public req: ApiRequestService,
    public dataService: DataService
  ) {
  }

  async createUser() {
    this.error = {}; // Инициализируем объект ошибок
    
    if (!this.password || this.password.length < 8) {
        this.error.password = "Password must be at least 8 characters long";
    }

    if (this.password !== this.confirmPassword) {
        this.error.confirmPassword = "Password mismatch";
    }

    // Проверка наличия имени и фамилии
    if (!this.firstname) {
        this.error.firstname = "First name is required";
    }

    if (!this.lastname) {
        this.error.lastname = "Last name is required";
    }

    // Проверка наличия email и его валидности
    if (!this.email) {
        this.error.email = "Email is required";
    } else {
        // Проверяем формат email с помощью регулярного выражения
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.email)) {
            this.error.email = "Invalid email format";
        }
    }
    if (Object.keys(this.error).length === 0) {
      const payload = {
        firstname: this.firstname,
        lastname: this.lastname,
        password: this.password,
        confirmPassword: this.confirmPassword,
        email: this.email,
        telephone: this.telephone,
        sex: this.sex,
        usergroup: this.usergroup
      };
      try {
        const employee = await axios.post(config.api.addEmployee, payload)
        if (employee.data.error) {
          this.error = employee.data.error
        }
        if (employee.data.success) {
           this.modalService.dismissAll();
        }
        
      } catch (error) {
        
      console.log("ASDASDASD", error)
      }
    }
    console.log("123123123")
    // // Проверка наличия телефона и его валидности (если он указан)
    // if (this.telephone && !/^\d{10}$/.test(this.telephone)) {
    //     this.error.telephone = "Invalid telephone number";
    // }


}


}
