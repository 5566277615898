import { Component, OnInit } from '@angular/core';
import { DataService } from "../../../app.service";
import config from '../../../../config.js'
import axios from 'axios'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
/**
 * Tabs-settings component
 */
export class SettingsComponent implements OnInit {

  senderName:any;
  senderProfile:any;
  playerName:any;
  userInfo;
  constructor(public dataService: DataService) { }

  ngOnInit(): void {
    const user = window.localStorage.getItem('currentUser');
    this.userInfo = this.dataService.currentUser;
    this.senderName = JSON.parse(user).username
    this.playerName = JSON.parse(user).username
    this.senderProfile = 'assets/images/users/'+JSON.parse(user).profile
  }

  // User Profile Update
  imageURL: string | undefined;
  async fileChange(event:any) {   
    
    const files = event.target.files
    if (!files || !files.length) return
    const file = files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('url', config.baseUrl)
    const headers = new Headers()
    headers.append('Content-Type', 'application/x-www-form-urlencoded')
    // Upload presentation logo
    const url = config.api.upload
    const res = await axios.post(url, formData)
      this.userInfo.avatarFullUrl = res.data.file.location
      this.userInfo.avatar = `/${res.data.file.path}`
      this.saveChanges('avatar')
  }

  // User Name Update
  edit_userName(message) {    
    this.playerName = this.senderName
    document.getElementById("user_name").classList.toggle("visually-hidden");
    document.getElementById("user_name_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-user-name").classList.toggle("visually-hidden");
  }

  // User Name Update
  edit_email(message) {    
    this.playerName = this.senderName
    document.getElementById("user_email").classList.toggle("visually-hidden");
    document.getElementById("user_email_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-user-email").classList.toggle("visually-hidden");
  }



edit_telephone(message) {    
  this.playerName = this.senderName
  document.getElementById("user_telephone").classList.toggle("visually-hidden");
  document.getElementById("user_telephone_edit").classList.toggle("visually-hidden");
  document.getElementById("edit-user-telephone").classList.toggle("visually-hidden");
}

edit_address(message) {    
  this.playerName = this.senderName
  document.getElementById("user_address").classList.toggle("visually-hidden");
  document.getElementById("user_address_edit").classList.toggle("visually-hidden");
  document.getElementById("edit-user-address").classList.toggle("visually-hidden");
}

edit_password(message) {    
  this.playerName = this.senderName
  document.getElementById("user_password").classList.toggle("visually-hidden");
  document.getElementById("user_password_edit").classList.toggle("visually-hidden");
  document.getElementById("edit-user-password").classList.toggle("visually-hidden");
}

edit_citizenship(message) {    
  this.playerName = this.senderName
  document.getElementById("user_citizenship").classList.toggle("visually-hidden");
  document.getElementById("user_citizenship_edit").classList.toggle("visually-hidden");
  document.getElementById("edit-user-citizenship").classList.toggle("visually-hidden");
}
  // User Name Update
userNameChange() {
  this.senderName = this.playerName
    document.getElementById("user_name").classList.toggle("visually-hidden");
    document.getElementById("edit-user-name").classList.toggle("visually-hidden");
    document.getElementById("user_name_edit").classList.toggle("visually-hidden");
 
}

async saveChanges(key) {
  if (key == 'name') {
    this.dataService.currentUser.firstname = this.userInfo.firstname;
    this.dataService.currentUser.lastname = this.userInfo.lastname;
  } else {
    this.dataService.currentUser[key] = this.userInfo[key]
  }

  await axios.put(config.api.updateProfile, this.dataService.currentUser)
  document.getElementById("user_"+key).classList.toggle("visually-hidden");
  document.getElementById("edit-user-"+key).classList.toggle("visually-hidden");
  document.getElementById("user_"+key+"_edit").classList.toggle("visually-hidden");
}

}
