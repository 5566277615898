<!-- Start profile content -->

<div class="offcanvas-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"></button>
</div>
<div>
  <div class="px-4 pt-4">
    <div class="user-chat-nav float-end">
      <div class="dropdown" ngbDropdown>
        <a href="javascript: void(0);" class="font-size-18 text-muted dropdown-toggle" ngbDropdownToggle
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-more-2-fill"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.edit'}}</a>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.action'}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.another'}}n</a>
        </div>
      </div>
    </div>
    <h4 class="mb-0">User profile</h4>
  </div>

  <div class="row p-4 border-bottom d-flex align-items-center">

  <div class="col-6">
  <div class="text-center">
    <div class="mb-4">
      <div *ngIf="profile.firstname"> 
        <div class="avatar" [ngClass]="type" [ngStyle]="{ 'background-image': 'url(' + profile.avatarFullUrl + ')'}" *ngIf="profile.avatarFullUrl" ></div>
        <div *ngIf="!profile.avatarFullUrl" [ngClass]="type" class="avatar horizontal layout center center-justified" [ngStyle]="{ 'background-color': profile.bgColor }">{{ user.firstname ? user.firstname[0] : "no-name"}}</div>
      </div> 
    </div>

    <h5 class="font-size-16 mb-1 text-truncate">{{gender[profile.sex]}} {{profile.firstname}} {{profile.lastname}}</h5>
    <p class="text-muted text-truncate mb-1" *ngIf="profile.online"><i
      class="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>
     
    Online</p>

    <p class="text-muted text-truncate mb-1" *ngIf="!profile.online"><i
      class="ri-record-circle-fill font-size-10 text-danger me-1 d-inline-block"></i>
     
    Offline</p>
    </div>
  </div>
  <div class="col-6">
  <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
    <ngb-panel cardClass="card shadow-none border mb-2" id="about">
      <ng-template ngbPanelTitle>
        <h5 class="font-size-14 m-0">
          <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
          Information
        </h5>
      </ng-template>
      <ng-template ngbPanelContent>
        <div>
          <p class="text-muted mb-1">Name</p>
          <h5 class="font-size-14">{{gender[profile.sex]}} {{profile.firstname}} {{profile.lastname}}</h5>
        </div>

        <div class="mt-4">
          <p class="text-muted mb-1">Citizenship</p>
          <h5 class="font-size-14">{{ profile.citizenship }}</h5>
        </div>

        <div class="mt-4">
          <p class="text-muted mb-1">Email</p>
          <h5 class="font-size-14">{{ profile.email }}</h5>
        </div>

        <div class="mt-4">
          <p class="text-muted mb-1">Phone</p>
          <h5 class="font-size-14">{{ profile.telephone }}</h5>
        </div>

        <div class="mt-4">
          <p class="text-muted mb-1">Address</p>
          <h5 class="font-size-14">{{ profile.address }}</h5>
        </div>

      </ng-template>
    </ngb-panel>

  </ngb-accordion>
</div>
</div>
  <!-- End profile user -->

  <button style="width:100%" (click)="showUploadModal()" class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light">Upload file</button>
       
  <!-- Start user-profile-desc -->
  <perfect-scrollbar class="p-4 user-profile-desc">

    <div class="text-muted">
      <p class="mb-4"></p>
    </div>
    <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
      <ngb-panel cardClass="card shadow-none border mb-2" id="file">
        <ng-template ngbPanelTitle>
          <h5 (click)="loadFiles()" class="font-size-14 m-0">
            <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>
            Related documents
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li  *ngFor="let types of files | keyvalue; let type_id = index " class="nav-item" role="presentation">
              <a class="nav-link" id="pills-{{types.key}}-tab" (click)="activetab=types.key" href="javascript: void(0);"
                [ngClass]="{'active': activetab === types.key}">
                <span *ngIf="types.key == 1">Personal Document</span>
                <span *ngIf="types.key == 2">Medical Report</span>
                <span *ngIf="types.key == 3">Prescriptions</span>
                <span *ngIf="types.key == 4">Invoice</span>
                <span *ngIf="types.key == 0">Others</span>
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div  *ngFor="let types of files | keyvalue; let type_id = index ">
              <div id="pills-{{types.key}}" role="tabpanel" aria-labelledby="pills-{{types.key}}-tab" *ngIf="activetab === types.key">
                
                <div class="date-files" *ngFor="let file_group of files[types.key] | keyvalue">
                  <p class="date">{{file_group.key}}
                  </p>
                  <div *ngFor="let file of file_group.value">
                    <div class="card p-2 border mb-2">
           
                      <div class="d-flex align-items-center">
                        <div class="avatar-sm me-3 ms-0">
                          <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                            <i class="ri-image-fill"></i>
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <div class="text-start">
                            <h5 class="font-size-14 mb-1">{{file.filename}}</h5>
                            <p class="text-muted font-size-13 mb-0">{{file.filesize }} KB
                            </p>
                            <p class="text-muted font-size-13 mb-0">{{file.date_added }}
                            </p>
                          </div>
                        </div>
                        <div class="ms-4 me-0">
                          <ul class="list-inline mb-0 font-size-18">
                            <li class="list-inline-item">
                              <a (click)="downloadFile(file.file_id, file.token, file.filename);" class="text-muted px-1">
                                <i class="ri-download-2-line"></i>
                              </a>
                            </li>
                            <li class="list-inline-item dropdown" ngbDropdown>
                              <a class="dropdown-toggle text-muted px-1" href="javascript: void(0);" ngbDropdownToggle role="button"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="ri-more-fill"></i>
                              </a>
                              <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                                <span *ngIf="file.note" class="dropdown-item"
                                  href="javascript:void(0);">
                                Notes: {{file.note}}
                              </span><span *ngIf="file.date_expired" class="dropdown-item"
                              href="javascript:void(0);">
                            Date expire: {{file.date_expired}}
                          </span>
                               
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                  </div> 

                  </div>
                </div>
            </div>
          </div>
          </div>
          
          <!-- end card -->

          <!-- end card -->
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <!-- end profile-user-accordion -->

  </perfect-scrollbar>
  <!-- end user-profile-desc -->
</div>
<!-- End profile content -->
