import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import { DataService } from "../../app.service";
// const config = require('../../../config.js')
// const config =  require('../../../config.js')
import config from '../../../config.js'
import { UploaduserfilePage } from '../uploaduserfile/uploaduserfile.page';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import axios from 'axios'
import {ApiRequestService} from '../../../service/requests.service'
import { ProfileEditPage } from '../profile-edit/profile-edit.page'
// const Auth =  require('../../../service/auth.js')
import Auth from '../../../service/auth.js'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";

@Component({
  selector: 'app-profile',
  templateUrl: 'profile.page.html',
  styleUrls: ['profile.page.scss']
})
export class ProfilePage {

  @Input() public profile;
  @Input() public currentUser;
  gender = ["", "Mr. ", "Ms. ",  "Mrs. ", "Dr. ", "Dra. "]
  submiting: any = false
  files = {}
  constructor(
    private modalService: NgbModal, 
    public req: ApiRequestService,
    private offcanvasService: NgbOffcanvas,
    public dataService: DataService,
    private router: Router,
  ) {
  }
  async loadFiles() {
    
    const payload = {
      user: this.profile,
    }
   let files = await axios.post(config.api.getRelatedFiles, payload)
   this.files = files.data.groupDocs;
  }
  async closeModal() {
    // await this.modalController.dismiss()
  }

  dismiss(reason) {
    this.offcanvasService.dismiss(reason)
  }
  async openEditProfile() {
  }

  async updateProfile() {

    if (this.submiting) return
    this.submiting = true
    const payload = {
      notification: this.dataService.currentUser.notification,
    }
    await axios.put(config.api.updateProfile, payload)
    this.submiting = false
  }
  async showUploadModal() {
    const modalRef = this.modalService.open(UploaduserfilePage, { centered: true });
    console.log(this.currentUser);
    modalRef.componentInstance.currentUser = this.currentUser;
    modalRef.componentInstance.profile = this.profile;
    // modalRef.dismissed.pipe().subscribe(data=>{this.getRelatedFiles()})
    modalRef.dismissed.subscribe(data=>{this.getRelatedFiles()})
    modalRef.closed.subscribe(data=>{this.getRelatedFiles()})
    // modalRef.close(result => {this.getRelatedFiles()})
    // modalRef.result.then(() => { this.getRelatedFiles(); }, () => { this.getRelatedFiles()})
  }
  async getRelatedFiles() {
    const res = await axios.get(config.api.getGroups)
    const groups = res.data.map((item:any) => {
      // console.log(res)
      const index = item.read.findIndex((mem:any) => {
        return mem.memberId === this.dataService.currentUser.id
      })
      if (index !== -1) {
        item.readCount = item.read[index].read
      }
      return item
    })
    this.dataService.groups = groups;
    if (!groups.length) {
      // let noMessElement = document.getElementById('no-message') as HTMLElement
      // noMessElement.classList.remove('hidden')
      // this._initImgNoMessage()
    }
  }
  logout() {
    Auth.removeToken()
    this.router.navigate(['/login'])
    this.closeModal()
  }
  async downloadFile(file_id, token, filename) {
    const payload = {
      file_id: file_id,
      token: token
    }
   let files = await axios.post(config.api.downloadFile, payload, {
    responseType: 'blob'  // Устанавливаем responseType в 'blob'
  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
});
   
  }
}
