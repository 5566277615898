
<div class="offcanvas-header">
  <button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"></button>
</div>
<div class="offcanvas-body profile-offcanvas p-0">
  <div class="text-center p-4 border-bottom">
    <div class="mb-4">
    <div *ngIf="group.name"> 
      <div class="avatar" [ngClass]="type" [ngStyle]="{ 'background-image': 'url(' + group.avatarFullUrl + ')'}" style="width: 150px;height: 150px;margin-bottom: 30px;" *ngIf="group.avatarFullUrl" ></div>
      <div *ngIf="!group.avatarFullUrl" [ngClass]="type" class="avatar horizontal layout center center-justified" style="width: 150px;height: 150px;margin-bottom: 30px;" [ngStyle]="{ 'background-color': group.member.bgColor }">{{ group.name ? group.name[0] : "no-name"}}</div>
    </div>
    </div>
    <h5 class="font-size-16 mb-1 text-truncate">{{group.name}}</h5>
    <p class="text-muted text-truncate mb-1">
      </p>
  </div>
  <perfect-scrollbar class="p-4 user-profile-desc">
    <div class="text-muted">
      <p class="mb-4 text-center">Created:  {{dateTime(group.createdAt)}}</p>
    </div>

    <div id="profile-user-accordion" class="custom-accordion">
      <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
        <ngb-panel cardClass="card shadow-none border mb-2" id="about">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
              Members
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <ul class="contact-list">
              <li  *ngFor="let item of members" [ngClass]="{'hidden': item.id == dataService.currentUser.id}">
               <div (click)="userInfo(item)">
                <div class="chat-user-img online align-self-center me-3 ms-0">
                  <img *ngIf="item.avatarFullUrl" src="{{item.avatarFullUrl}}" class="rounded-circle avatar-xs"
                    alt="">
                  <div class="avatar-xs" *ngIf="!item.avatarFullUrl ">
                    <span class="avatar-title rounded-circle"  [ngStyle]="{ 'background': item.bgColor, 'color': '#fff' }">
                      {{( item.firstname ? item.firstname : "No name" ).charAt(0)}}
                    </span>
                  </div>
                </div>
                <label>
                  <h2>{{gender[item.sex]}} {{item.firstname}} {{item.lastname}}</h2>
                  <p class="text-muted text-truncate mb-1" *ngIf="item.online"><i
                    class="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>
                   
                  Online</p>
              
                  <p class="text-muted text-truncate mb-1" *ngIf="!item.online"><i
                    class="ri-record-circle-fill font-size-10 text-danger me-1 d-inline-block"></i>
                   
                  Offline</p>
                  <h3 v-if="item.phone">{{item.telephone}}</h3> 
                  <p v-if="item.address">{{item.address}}</p>
                </label>
              </div>
            </li>
            </ul>
            <button style="width:100%" (click)="openInvite()" class="btn btn-light font-size-16 btn-lg chat-send waves-effect waves-light">Add members</button>
          </ng-template>
        </ngb-panel>
        <ngb-panel cardClass="card shadow-none border mb-2" id="file">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>Attachments
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file1.name' }}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file1.size' }}
                    </p>
                  </div>
                </div>

                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                          translate}}</a>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                          translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                          translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file2.name' }}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file2.name' }}
                    </p>
                  </div>
                </div>

                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript: void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                          translate}}</a>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                          translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                          translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-image-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file3.name' }}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file3.size' }}
                    </p>
                  </div>
                </div>

                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                          translate}}</a>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                          translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                          translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->

            <div class="card p-2 border mb-2">
              <div class="d-flex align-items-center">
                <div class="avatar-sm me-3 ms-0">
                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                    <i class="ri-file-text-fill"></i>
                  </div>
                </div>
                <div class="flex-grow-1">
                  <div class="text-start">
                    <h5 class="font-size-14 mb-1">{{'chat.rightpanel.attachedfile.file4.name' }}</h5>
                    <p class="text-muted font-size-13 mb-0">{{'chat.rightpanel.attachedfile.file4.size' }}
                    </p>
                  </div>
                </div>

                <div class="ms-4 me-0">
                  <ul class="list-inline mb-0 font-size-18">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="text-muted px-1">
                        <i class="ri-download-2-line"></i>
                      </a>
                    </li>
                    <li class="list-inline-item dropdown" ngbDropdown>
                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"
                        role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="ri-more-fill"></i>
                      </a>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.action' |
                          translate}}</a>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.another' |
                          translate}}</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item"
                          href="javascript:void(0);">{{'chat.rightpanel.attachedfile.dropdown.delete' |
                          translate}}</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- end card -->
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- End Attached Files card -->
    </div>
    <!-- end profile-user-accordion -->
  </perfect-scrollbar>



</div>