import moment from 'moment'

const hashCode = (str) => {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
     hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
} 

const intToRGB = (i) => {
  var c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
}

const randomeColorByStr = (str = 'njdksksk') => {
  return intToRGB(hashCode(str))
}

const dateTime = (value) => {
  let date = new Date()
  if (value) date = new Date(value)
  const today = moment(new Date()).format('DD-MM-YYYY')
  const FromDay = moment(date).format('DD-MM-YYYY')
  if (today != FromDay) return moment(date).format('MM-DD hh:mm A')
  return moment(date).format('hh:mm A')
}

export default {
  randomeColorByStr,
  dateTime,
}
