<div class="modal-body">
  <div class="text-center p-4">
    <h5 class="text-truncate">Create new user</h5>
    <div class="text-left">
    <div class="form-group mt-4">
      <label for="role">Role</label>
      <select class="form-control" id="role" [(ngModel)]="usergroup">
        <option value="1">Superadmin</option>
        <option value="2">Operator</option>
        <option value="3">Doctor</option>
      </select>
    </div>


    <div class="form-group mt-4">
      <label for="prefix">Prefix</label>
      <select class="form-control" id="prefix" [(ngModel)]="sex">
        <option value="4">Dr</option>
        <option value="5">Dra</option>
        <option value="2">Ms</option>
        <option value="3">Mrs</option>
        <option value="1">Mr</option>
      </select>
    </div>
    <div class="form-group mt-4">
      <label for="firstname">Firstname</label>
      <div class="error" *ngIf="error && error.firstname">{{error.firstname}}</div>
      <input type="text" autocomplete="off" class="form-control" id="firstname" [(ngModel)]="firstname" required>
    </div>
    <div class="form-group mt-4">
      <label for="lastname">Lastname</label>
      <div class="error" *ngIf="error && error.lastname">{{error.lastname}}</div>
      <input type="text" autocomplete="off" class="form-control" id="lastname" [(ngModel)]="lastname" required>
    </div>
    <div class="form-group mt-4">
      <label for="email">Email</label>
      <div class="error" *ngIf="error && error.email">{{error.email}}</div>
      <input type="email" class="form-control" id="email" [(ngModel)]="email" required>
    </div>
    <div class="form-group mt-4">
      <label for="password">Password</label>
      <div class="error" *ngIf="error && error.password">{{error.password}}</div>
      <input type="password" class="form-control" id="password" [(ngModel)]="password" required>
    </div>
    <div class="form-group mt-4">
      <label for="confirmPassword">Confirm Password</label>
      <div class="error" *ngIf="error && error.confirmPassword">{{error.confirmPassword}}</div>
      <input type="password" class="form-control" id="confirmPassword" [(ngModel)]="confirmPassword" required>
    </div>
    <div class="form-group mt-4">
      <label for="telephone">Telephone</label>
      <div class="error" *ngIf="error && error.telephone">{{error.telephone}}</div>
      <input type="tel" class="form-control" id="telephone" [(ngModel)]="telephone">
    </div>
  </div>
    <button (click)="createUser()" class="btn btn-primary mt-4">Submit</button>
 
  </div>
</div>