import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { InvitePage } from './invite.page';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [InvitePage]
})
export class InvitePageModule {}
