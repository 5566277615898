<!-- Start profile content -->
<div>
  <div class="px-4 pt-4">
    <div class="user-chat-nav float-end">
      <div class="dropdown" ngbDropdown>
        <a href="javascript: void(0);" class="font-size-18 text-muted dropdown-toggle" ngbDropdownToggle
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="ri-more-2-fill"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.edit' | translate}}</a>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.action' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:void(0);">{{'chat.tabs.profile.dropdown.another' | translate}}n</a>
        </div>
      </div>
    </div>
    <h4 class="mb-0">{{'chat.tabs.profile.title' | translate }}</h4>
  </div>

  <div class="text-center p-4 border-bottom">
    <div class="mb-4">
      <div *ngIf="dataService.currentUser.firstname"> 
        <div class="avatar" [ngClass]="type" [ngStyle]="{ 'background-image': 'url(' + dataService.currentUser.avatarFullUrl + ')'}" *ngIf="dataService.currentUser.avatarFullUrl" ></div>
        <div *ngIf="!dataService.currentUser.avatarFullUrl" [ngClass]="type" class="avatar horizontal layout center center-justified" [ngStyle]="{ 'background-color': dataService.currentUser.bgColor }">{{ dataService.currentUser.firstname ? dataService.currentUser.firstname[0] : "no-name"}}</div>
      </div>    
    </div>

    <h5 class="font-size-16 mb-1 text-truncate">{{senderName | translate}}</h5>
    <p class="text-muted text-truncate mb-1"><i
        class="ri-record-circle-fill font-size-10 text-success me-1 d-inline-block"></i>
      {{'chat.tabs.profile.status' | translate}}</p>
  </div>

  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <perfect-scrollbar class="p-4 user-profile-desc">

    <div class="text-muted">
      <p class="mb-4"></p>
    </div>

    <ngb-accordion #acc="ngbAccordion" activeIds="file" [closeOthers]="true">
      <ngb-panel cardClass="card shadow-none border mb-2" id="about">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">
            <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>
            {{'chat.tabs.profile.about.text' | translate}}
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div>
            <p class="text-muted mb-1">{{ 'chat.tabs.profile.about.name.text' | translate}}</p>
            <h5 class="font-size-14">{{ gender[dataService.currentUser.sex] }} {{dataService.currentUser.firstname}} {{dataService.currentUser.lastname}}</h5>
          </div>

          <div class="mt-4">
            <p class="text-muted mb-1">{{ 'chat.tabs.profile.about.email.text' | translate}}</p>
            <h5 class="font-size-14">{{ dataService.currentUser.email }}</h5>
          </div>

          <div class="mt-4">
            <p class="text-muted mb-1">Phone</p>
            <h5 class="font-size-14">{{ dataService.currentUser.phone }}</h5>
          </div>

          <div class="mt-4">
            <p class="text-muted mb-1">Address</p>
            <h5 class="font-size-14">{{ dataService.currentUser.address }}</h5>
          </div>

        </ng-template>
      </ngb-panel>
      <ngb-panel cardClass="card shadow-none border mb-2" id="file">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">
            <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>
            Personal QR-code
          </h5>
        </ng-template>
        <ng-template ngbPanelContent>
        
        <div class="card p-2 border mb-2">
      
  <div class="mb-4 profile-user">
    <p>Invitation QR code</p>
    <qrcode [qrdata]="dataService.currentUser.invite_token" [width]="256" [errorCorrectionLevel]="'M'"></qrcode> 
    
    <p>Invitation link</p>
    <div id="edit-company_name" class="d-flex justify-content-between">
      <input  [value]="'https://interactivetelecare.com/personalInvite.php?token=' + dataService.currentUser.invite_token" type="text" #userinput readonly class="form-control bg-soft-light border-light"
        />
      <div class="float-right">
        <button (click)="copyInputMessage(userinput)"
          class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
          {{text_copy}}</button>
      </div>
    </div>
  </div>

          </div> 
        
          <!-- end card -->

          <!-- end card -->
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <!-- end profile-user-accordion -->

  </perfect-scrollbar>
  <!-- end user-profile-desc -->
</div>
<!-- End profile content -->
