
import util from '../../../util.js'
import { Component, Input } from '@angular/core';
import { DataService } from "../../app.service";
import config from '../../../config.js'
import axios from 'axios'
import {ApiRequestService} from '../../../service/requests.service'
import { ProfileEditPage } from '../profile-edit/profile-edit.page'

@Component({
  selector: 'app-invite',
  templateUrl: 'invite.page.html',
  styleUrls: ['invite.page.scss']
})
export class InvitePage {
  @Input() public call;
  contacts: any = []
  textSearch: string = ''
  submiting: boolean = false
  groupName: string = ''
  invited: any = []
  constructor(
    public req: ApiRequestService,
    public dataService: DataService
  ) {
    this.getContact()
  }

  async getContact() {
    const res = await axios.get(config.api.contact)
    this.contacts = res.data
  }

  contactList() {
    if (!this.textSearch) return this.contacts
    return this.contacts.filter((item:any) => {
      const textSearch = this.textSearch.toLowerCase()
      const name = item.firstname.toLowerCase()
      const lastname = item.lastname.toLowerCase()
      let phone = ''
      let email = ''
      if (item.phone) phone = item.telephone
      // if (item.lastname) lastname = item.lastname
      if (item.email) email = item.email
      return name.indexOf(textSearch) !== -1 || lastname.indexOf(textSearch) !== -1 || phone.indexOf(textSearch) !== -1 || email.indexOf(textSearch) !== -1
    })
  }
  async invite(user) {    
    const payload: any = {
      user_id: user.id,
      group_id: this.dataService.group.id,
      call: this.call
    }
    const group = await axios.post(config.api.invite, payload)
    this.dataService.group.members.push(user.id)
    this.invited.push(user.id)
    console.log(user, this.dataService.group)
  }

  contactSelect() {
    return this.contacts.filter((item:any) => {
      return item.selected
    })
  }

  async createGroup() {
    if (this.submiting) return
    const ids = this.contacts.filter((item:any) => {
      return item.selected
    }).map((item:any) => {
      return item.id
    })
    if (!ids.length || this.submiting) return
    const payload: any = {
      members: ids,
      lastMessage: `${this.dataService.currentUser.fullName} created chat`
    }
    if (ids.length > 1) {
      payload.name = this.groupName || 'Group'
    }

    this.submiting = true
    console.log(config.api.createGroup, payload)
    const group = await axios.post(config.api.createGroup, payload)
    this.messageSave()
    this.submiting = false
  }


  messageSave() {
    const message = "text_invite";
    
    if (!message) return
    const payload = {
      text: message
    }
    this._sendMessage(payload)

   }


  async _sendMessage(payload:any) {
    payload.group = this.dataService.group.id
    const message = {...payload}
    message.createdBy = this.dataService.currentUser.id
    message.from = this.dataService.currentUser
    // alert(new Date())
    message.createdAt = util.dateTime()
    message.updateAt = util.dateTime()
    if (payload.image) {
      message.image = payload.imageFullUrl
      delete payload.imageFullUrl
    }

    if (payload.video) {
      message.video = payload.videoFullUrl
      delete payload.videoFullUrl
    }
    // this.text = ''
    const res = await axios.post(config.api.createMessage, payload)
  }
}
