import { Component, OnInit } from '@angular/core';
import { DataService } from "../../../app.service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
/**
 * Tabs-Profile component
 */
export class ProfileComponent implements OnInit {

  gender = ["", "Mr. ", "Ms. ",  "Mrs. ", "Dr. ", "Dra. "]
  senderName:any;
  senderProfile:any;
  text_copy = "Copy"
  constructor(
    public dataService: DataService) { }

  ngOnInit(): void {
  }

  copyInputMessage(inputElement){
    this.text_copy = 'Copied!'
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    setTimeout(() => {
      this.text_copy = "Copy"
    }, 1000);
  }
}
